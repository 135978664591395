<template>
  <div>
    <div class="balance-card">
      <div>
        <div class="bb-intro-top invite-card">
          <!-- <i class="fas fa-donate"></i>
          <a class="b-icon-title"> {{ $t("toefl.Your T-Coin Balance") }}</a> -->
          <div>
            <i class="fas fa-donate"></i>
            <b class="b-icon-title">
              {{ $t("toefl.Invitation Link") }}
            </b>
          </div>
          <div class="coin-button">
            <span style="padding: 3px 0;margin-right: 10px" type="text">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  <b>
                    <i class="el-icon-warning"></i>
                    {{ $t("sat.balance.Invitees need to verify their email") }}
                  </b>
                  <!-- <hr class="mt-1 mb-1 p-0" style="border-color: white;" />
                  <span
                    v-html="
                      $t('sat.balance.Invited', {
                        Invited: referral ? referral.usages.length : 0,
                        Verified: userCorrect
                      })
                    "
                  >
                  </span> -->
                </div>
                <span style="cursor: pointer;">
                  {{ $t("toefl.Members invited") }}
                  <i class="el-icon-question"></i>
                </span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" placement="top">
                <b slot="content">
                  View
                </b>
                <b
                  class="ml-2 text-success view-invitation"
                  @click="viewInvitation"
                >
                  <template v-if="referral">
                    <span
                      :class="userCorrect < 2 ? 'text-danger' : 'text-success'"
                      >{{ userCorrect }}</span
                    >
                  </template>
                  <span v-else class="text-danger">
                    0
                  </span>
                </b>
              </el-tooltip>
              / 2
            </span>
            <el-tag style="cursor: pointer;" @click="showDetails = true">
              <b>{{ $t("sat.balance.Get 10 Coins for every 2 invites") }}</b>
            </el-tag>
          </div>
        </div>
        <div class="b-info">
          <div class="info text-center">
            <span type="info">
              {{ $t("toefl.Share Link") }}
            </span>
          </div>
          <div
            class="text-center"
            v-if="referral_code === null || referral_code.length < 1"
          >
            <el-button type="success" @click="createReferralCode">
              {{ $t("toefl.Show My Invitation Link") }}
            </el-button>
          </div>
          <div class="text-center" v-else>
            <div class="referral-code">
              <a>
                <span>{{ link }}</span>
              </a>
            </div>
            <el-button type="success" @click="copyLink">
              {{ $t("toefl.Copy Link") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import User from "@/apis/user.js";
import Balance from "@/apis/balance.js";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  components: {},

  mixins: [],

  props: ["role", "getProfile"],
  data() {
    return {
      referral_code: null,
      ReferralUsers: 0,
      has_exchanged: false,
      referral: null,
      userAll: 0,
      userCorrect: 0,
      showDetails: false
    };
  },
  computed: {
    // ...mapGetters("user", ["getProfile"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    link() {
      return `${baseDomainPath}register?referral_code=${this.referral_code}`;
    }
  },
  watch: {
    getProfile() {
      this.referral =
        this.getProfile.referral_codes &&
        this.getProfile.referral_codes.length > 0
          ? this.getProfile.referral_codes[0]
          : null;
      this.referral_code = this.referral.code;
      if (this.referral) {
        this.referral.usages.forEach(user => {
          if (user.email_verified_at && user.pivot.valid_ip !== 0) {
            this.userCorrect++;
          }
        });
      }
    }
  },

  mounted() {
    this.referral =
      this.getProfile.referral_codes &&
      this.getProfile.referral_codes.length > 0
        ? this.getProfile.referral_codes[0]
        : null;
    this.referral_code = this.referral.code;
    if (this.referral) {
      this.referral.usages.forEach(user => {
        if (user.email_verified_at && user.pivot.valid_ip !== 0) {
          this.userCorrect++;
        }
      });
    }
    // this.getReferralUsed();
  },

  methods: {
    viewInvitation() {
      this.$emit("viewInvitation");
    },
    async createReferralCode() {
      const res = await Balance.getReferralCodes();
      this.referral_code = res.referral_code.code;
    },
    // async getReferralUsed() {
    //   const res = await User.getReferralUsed();
    //   this.ReferralUsers = res.referral_code_used_count;
    //   this.has_exchanged = res.has_exchanged;
    // },
    async exchangePoint() {
      await User.exchangePoint();
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.$router.go(0);
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({
        message: this.$t("Profile.Copied"),
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.view-invitation:hover {
  text-decoration: underline;
  cursor: pointer;
}
.invite-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance-card {
  height: 100%;
}
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 1rem;
}
.info {
  margin-bottom: 20px;
  font-size: 14px;
}

@media screen and (max-width: 400px) {
  ::v-deep .el-card__header {
    height: 150px;
  }
  .info {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
<style scoped>
.b-info {
  padding: 4rem 0;
}
.b-info .balance-price {
  margin-left: 10px;
  color: #757575;
}
.b-info .balance-name {
  color: #757575;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 28px 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 18px;
  /* color: #99a2aa; */
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
@media screen and (max-width: 500px) {
  .b-info {
    padding: 15px;
  }
  .invite-card {
    display: block;
  }
  .referral-code span {
    padding: 5px;
  }
}
</style>
